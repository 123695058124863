import Index from "../Pages/Index";
import Message from "../Pages/Message";
import MessageSend from "../Pages/MessageSend";
import MessageEvent from "../Pages/MessageEvent";
import MessageLog from "../Pages/MessageLog";

const Menu = [
  { path: "/", name: "home", element: <Index /> },
  { path: "/message", name: "message", element: <Message /> },
  { path: "/messageSend", name: "messageSend", element: <MessageSend /> },
  { path: "/messageEvent", name: "messageEvent", element: <MessageEvent /> },
  { path: "/messageLog", name: "messageLog", element: <MessageLog /> },
];

export default Menu;