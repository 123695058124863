import React, { useState } from "react";
import { Table } from "react-bootstrap";
import moment from "moment";
import { BiSearchAlt, BiTrash } from "react-icons/bi";
import { message } from "../../Helpers/useToast";
import ConfirmToDelete from "./ConfirmToDelete";
import Details from "./Details";
import Heading from "../Meta/Heading";

function Read() {
  const messageList = [
    {
      "reportMessageID": 4,
      "title": "Music Festival is here",
      "templateID": 21,
      "broadCast": "2022-11-29T04:50:00.000Z",
      "status": "0",
      "created": "2022-11-29T04:25:18.000Z",
      "subject": "CompName:",
      "message": "Join our Music Festival @ JB on June 10th",
      "messageType": "Customise Message",
      "totalContact": 2,
      "eventStatus": "Queuing"
  },
    {
        "reportMessageID": 1,
        "title": "Registration 2022 Batch 1",
        "templateID": 6,
        "broadCast": "2022-11-29T03:33:48.000Z",
        "status": "1",
        "created": "2022-11-29T03:31:48.000Z",
        "subject": "CompName:",
        "message": "Hi, your registration id is {id}",
        "messageType": "Customise Message",
        "totalContact": 1,
        "eventStatus": "Completed"
    },
    {
        "reportMessageID": 2,
        "title": "Food Festival Inviation",
        "templateID": 21,
        "broadCast": "2022-11-29T04:30:00.000Z",
        "status": "1",
        "created": "2022-11-29T04:27:13.000Z",
        "subject": "ABC:",
        "message": "We would like to invite you to our Food Festival",
        "messageType": "Customise Message",
        "totalContact": 2,
        "eventStatus": "Completed"
    },
    {
        "reportMessageID": 3,
        "title": "Garage Gathering",
        "templateID": 21,
        "broadCast": "2022-11-29T04:50:00.000Z",
        "status": "2",
        "created": "2022-11-29T04:25:18.000Z",
        "subject": "ABC:",
        "message": "Let's gather in our garage to have fun",
        "messageType": "Customise Message",
        "totalContact": 2,
        "eventStatus": "Cancelled"
    },
]

  const [isConfirm, setIsConfirm] = useState({ modal: false, id: 0 });
  const [isDetails, setIsDetails] = useState({ modal: false, id: 0 });


  const deleteEvent = (id) => {
    setIsConfirm({ modal: false, id: 0 });
    return message("Broadcast is Deleted successfully", "success");
  };

  return (
    <div className="m-2 p-4">
      {messageList.length > 0 ? (
        <Table striped bordered hover responsive className="bg-light">
          <thead>
            <tr>
              <th>Actions</th>
              <th>No</th>
              <th>Type</th>
              <th>Title</th>
              <th>Content</th>
              <th>No of contacts</th>
              <th>Status</th>
              <th>Created @</th>
              <th>BroadCast @</th>
            </tr>
          </thead>
          <tbody>
            {messageList.map((messageListDetail, index) => {
              const messageEventID = messageListDetail.reportMessageID;
              const messageType = messageListDetail.messageType;
              const title = messageListDetail.title;
              const totalContact = messageListDetail.totalContact;

              const content =
                messageListDetail.subject + messageListDetail.message;
              const eventStatus = messageListDetail.eventStatus;
              const created = moment(messageListDetail.created).format(
                "YYYY-MM-DD HH:mm"
              );
              const broadCast = moment(messageListDetail.broadCast).format(
                "YYYY-MM-DD HH:mm"
              );

              return (
                <tr key={index}>
                  <td className="text-nowrap">
                    <BiSearchAlt
                      onClick={() => {
                        setIsDetails({ modal: true, id: messageEventID });
                      }}
                      className="icon"
                    />
                    {eventStatus === "Queuing" && (
                      <BiTrash
                        onClick={() => {
                          setIsConfirm({ modal: true, id: messageEventID });
                        }}
                        className="icon"
                      />
                    )}
                  </td>
                  <td>{index + 1}</td>
                  <td>{messageType}</td>
                  <td>{title}</td>
                  <td>{content}</td>
                  <td>{totalContact}</td>
                  <td>{eventStatus}</td>
                  <td>{created}</td>
                  <td>{broadCast}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <Heading content="No Content yet!" design="h4 text-center text-dark" />
      )}
      <ConfirmToDelete
        deleteEvent={deleteEvent}
        isConfirm={isConfirm}
        setIsConfirm={setIsConfirm}
      />
      <Details
        isDetails={isDetails}
        setIsDetails={setIsDetails}
        messageList={messageList}
      />
    </div>
  );
}

export default Read;
