import React from "react";
import { Table } from "react-bootstrap";
import Heading from "../Meta/Heading";
const moment = require("moment");

function Read() {
  const messageList = [
    {
        "logMessageID": 1,
        "reportMessageID": 1,
        "phoneNumber": 60123456789,
        "template": "CompName: Hi, your registration id is 60123456789",
        "ref": "E2211QbYEnpW9bz",
        "schedule": "2022-11-29T04:30:00.000Z",
        "status": "1",
        "created": "2022-11-29T04:27:13.000Z",
        "tsStatus": "Completed",
        "title": "Registration 2022 Batch 1"
    },
    {
        "logMessageID": 2,
        "reportMessageID": 1,
        "phoneNumber": 60111111111,
        "template": "CompName: Hi, your registration id is 60111111111",
        "ref": "E2211PdyPDlwzbQ",
        "schedule": "2022-11-29T04:30:00.000Z",
        "status": "1",
        "created": "2022-11-29T04:27:13.000Z",
        "tsStatus": "Completed",
        "title": "Registration 2022"
    },
    {
        "logMessageID": 3,
        "reportMessageID": 1,
        "phoneNumber": 60111111112,
        "template": "CompName: Hi, your registration id is 60111111112",
        "ref": "E22119aAD7Bm9ev",
        "schedule": "2022-11-29T03:42:00.000Z",
        "status": "1",
        "created": "2022-11-29T03:31:48.000Z",
        "tsStatus": "Completed",
        "title": "Registration 2022"
    },
    {
        "logMessageID": 4,
        "reportMessageID": 2,
        "phoneNumber": 60111111111,
        "template": "CompName: We would like to invite you to our Food Festival",
        "ref": null,
        "schedule": "2022-11-29T04:50:00.000Z",
        "status": "2",
        "created": "2022-11-29T04:25:18.000Z",
        "tsStatus": "Cancelled",
        "title": "Food Festival Inviation"
    },
    {
        "logMessageID": 5,
        "reportMessageID": 2,
        "phoneNumber": 60111111112,
        "template": "CompName: We would like to invite you to our Food Festival",
        "ref": null,
        "schedule": "2022-11-29T04:50:00.000Z",
        "status": "2",
        "created": "2022-11-29T04:25:18.000Z",
        "tsStatus": "Cancelled",
        "title": "Food Festival Inviation"
    },
    {
        "logMessageID": 6,
        "reportMessageID": 3,
        "phoneNumber": 60111111112,
        "template": "ABC: Let's gather in our garage to have fun",
        "ref": null,
        "schedule": "2022-11-29T04:50:00.000Z",
        "status": "2",
        "created": "2022-11-29T04:34:55.000Z",
        "tsStatus": "Cancelled",
        "title": "Garage Gathering"
    },
    {
        "logMessageID": 7,
        "reportMessageID": 3,
        "phoneNumber": 60108806746,
        "template": "ABC: Let's gather in our garage to have fun",
        "ref": null,
        "schedule": "2022-11-29T04:50:00.000Z",
        "status": "2",
        "created": "2022-11-29T04:34:55.000Z",
        "tsStatus": "Cancelled",
        "title": "Garage Gathering"
    },
    {
        "logMessageID": 8,
        "reportMessageID": 3,
        "phoneNumber": 60108806747,
        "template": "ABC: Let's gather in our garage to have fun",
        "ref": null,
        "schedule": "2022-11-29T04:50:00.000Z",
        "status": "2",
        "created": "2022-11-29T04:34:55.000Z",
        "tsStatus": "Cancelled",
        "title": "Garage Gathering"
    },
    {
        "logMessageID": 9,
        "reportMessageID": 4,
        "phoneNumber": 601234545678,
        "template": "ABC: Hi Jimmy, Grab our donuts to get offers",
        "ref": null,
        "schedule": "2022-11-29T04:50:00.000Z",
        "status": "2",
        "created": "2022-11-29T04:34:55.000Z",
        "tsStatus": "Cancelled",
        "title": "Offer"
    },
    {
        "logMessageID": 10,
        "reportMessageID": 4,
        "phoneNumber": 601234545633,
        "template": "ABC: Hi John, Grab our donuts to get offers",
        "ref": null,
        "schedule": "2022-11-29T04:50:00.000Z",
        "status": "2",
        "created": "2022-11-29T04:34:55.000Z",
        "tsStatus": "Cancelled",
        "title": "Offer"
    },
];

  return (
    <div className="m-2 p-4">
      {messageList.length > 0 ? (
        <Table striped bordered hover responsive className="bg-light">
          <thead>
            <tr>
              <th>No</th>
              <th>Title</th>
              <th>Content</th>
              <th>To</th>
              <th>Status</th>
              <th>Sent @</th>
              <th>Reference ID</th>
            </tr>
          </thead>
          <tbody>
            {messageList.map((messageListDetail, index) => {
              const title = messageListDetail.title;
              const template = messageListDetail.template;
              const phoneNumber = messageListDetail.phoneNumber;
              const status = messageListDetail.tsStatus;
              const schedule = moment(messageListDetail.schedule).format(
                "YYYY-MM-DD HH:mm"
              );
              const ref = messageListDetail.ref;

              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{title}</td>
                  <td>{template}</td>
                  <td>{phoneNumber}</td>
                  <td>{status}</td>
                  <td>{schedule}</td>
                  <td>{ref}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <Heading content="No Content yet!" design="h4 text-center text-dark" />
      )}
    </div>
  );
}

export default Read;
