import React from "react";
import { message } from "../../Helpers/useToast";

import { Table } from "react-bootstrap";
import { BiEdit, BiTrash } from "react-icons/bi";

function Read({ setShow }) {
  const messageList = [
    {
      messageTemplateID: 1,
      type: 1,
      name: "Registration 2022",
      languageID: 1,
      subject: "CompName:",
      message: "Hi, your registration id is {id}",
      characters: 77,
      credit: 1,
      isUnicode: "N",
      status: "N",
      created: "2022-11-25T06:11:19.000Z",
      language: "English",
    },
    {
      messageTemplateID: 2,
      type: 0,
      name: "Food Festival Inviation",
      languageID: 1,
      subject: "CompName:",
      message: "We would like to invite you to our Food Festival",
      characters: 100,
      credit: 1,
      isUnicode: "N",
      status: "N",
      created: "2022-11-25T06:11:19.000Z",
      language: "English",
    },
    {
      messageTemplateID: 3,
      type: 0,
      name: "Garage Gathering",
      languageID: 1,
      subject: "ABC:",
      message: "Let's gather in our garage to have fun",
      characters: 100,
      credit: 1,
      isUnicode: "N",
      status: "N",
      created: "2022-11-25T06:11:19.000Z",
      language: "English",
    },

    {
      messageTemplateID: 4,
      type: 1,
      name: "Offer",
      languageID: 1,
      subject: "ABC:",
      message: "Hi {name}, Grab our donuts to get offers",
      characters: 100,
      credit: 1,
      isUnicode: "N",
      status: "N",
      created: "2022-11-25T06:11:19.000Z",
      language: "English",
    },
  ];

  const deleteTemplate = (id) => {
    return message("Successfully deleted", "success");
  };

  return (
    <div className="m-2 p-4">
      {messageList.length > 0 && (
        <Table striped bordered hover responsive className="bg-light">
          <thead>
            <tr>
              <th>No</th>
              <th>Type</th>
              <th>Name</th>
              <th>Language</th>
              <th>Subject</th>
              <th>Message</th>
              <th>Template</th>
              <th>Unicode</th>
              <th>Characters</th>
              <th>Credit(s)</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {messageList.map((messageListDetail, index) => {
              const messageType = messageListDetail.type;
              const name = messageListDetail.name;
              const language = messageListDetail.language;
              const subject = messageListDetail.subject;
              const message = messageListDetail.message;
              const template =
                "RM0 " + messageListDetail.subject + messageListDetail.message;
              const characters = messageListDetail.characters;
              const credit = messageListDetail.credit;
              const isUnicode = messageListDetail.isUnicode;
              const messageID = messageListDetail.messageTemplateID;

              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{messageType === 0 ? "Normal" : "Customise"}</td>
                  <td>{name}</td>
                  <td>{language}</td>
                  <td>{subject}</td>
                  <td>{message}</td>
                  <td>{template}</td>
                  <td>{isUnicode === "N" ? "No" : "Yes"}</td>
                  <td>{characters}</td>
                  <td>{credit}</td>
                  <td>
                    <BiEdit
                      onClick={() => {
                        setShow(true);
                      }}
                    />{" "}
                    |{" "}
                    <BiTrash
                      onClick={() => {
                        deleteTemplate(messageID);
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </div>
  );
}

export default Read;
