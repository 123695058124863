import React from "react";
import { Button, Modal } from "react-bootstrap";
import Heading from "../Meta/Heading";

function ConfirmToDelete({
deleteEvent,
  isConfirm,
  setIsConfirm,
}) {
  return (
    <Modal
      size="md"
      show={isConfirm.modal}
      backdrop="static"
      keyboard={false}
      onHide={() => setIsConfirm({modal: false, id:"0"})}
      aria-labelledby="modal-isConfirm"
    >
      <Modal.Header closeButton>
        <Modal.Title id="modal-isConfirm">
          <Heading content="Confirmation" design="h3" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="mb-0">Are you sure to stop the broadcast?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={()=> {deleteEvent(isConfirm.id)}}>
          Confirm
        </Button>
        <Button variant="outline-secondary" onClick={() => setIsConfirm({modal: false, id: "0"})}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmToDelete;
