import React from "react";
import { Button, Modal } from "react-bootstrap";
import Heading from "../Meta/Heading";

function ConfirmToSend({
submitForm,
  isConfirm,
  setIsConfirm,
  submitValue,
  setSubmitValue,
}) {
  return (
    <Modal
      size="md"
      show={isConfirm}
      backdrop="static"
      keyboard={false}
      onHide={() => setIsConfirm(false)}
      aria-labelledby="modal-isConfirm"
    >
      <Modal.Header closeButton>
        <Modal.Title id="modal-isConfirm">
          <Heading content="Confirmation" design="h3" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="mb-0">Are you sure to submit?</p>
        {submitValue.scheduleValue === "0" ? (
          <>
            <p className="mb-0">
              System will send the message 1 minute later.{" "}
            </p>
            <p className="mb-0">You can cancel the blast within 1 minute.</p>
          </>
        ) : (
          <p className="mb-0">
            You can cancel the blast in anytime before the event start.
          </p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={submitForm}>
          Confirm
        </Button>
        <Button variant="outline-secondary" onClick={() => setIsConfirm(false)}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmToSend;
