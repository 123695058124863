import React from "react";
import { Col } from "react-bootstrap";
import Heading from "../Heading";

function MessageRule() {
  return (
    <div>
      <Col sm={12}>
        <Heading content="Template Example:" design="h6 text-secondary" />
      </Col>
      <Col sm={12} className="mb-3 px-3">
        <p className="fs-7 mb-0 fw-bold">Normal Message Template:</p>
        <p className="fs-7 mb-2">
          "It's International Music's month. Get your free Ukulele. visit our
          website today."
        </p>
        <p className="fs-7 mb-0 fw-bold">Customise Message Template:</p>
        <p className="fs-7 mb-2">
          "<span className="text-danger">AA BookStore</span>: Dear{" "}
          <span className="text-danger">Jimmy Doe</span>, your membership is
          expired soon on <span className="text-danger">10/06/2023</span>. TQ."
        </p>
        <p className="fs-7 mb-2">
          "<span className="text-danger">AA BookStore</span>: Dear{" "}
          <span className="text-danger">Mark Khor</span>, your membership is
          expired soon on <span className="text-danger">15/09/2019</span>. TQ."
        </p>
        <p className="fs-7 mb-0 fw-bold">Template Message Compose:</p>
        <p className="fs-7 mb-0">
          -<span className="text-danger">{" {"}</span>BrandName
          <span className="text-danger">{"}"}</span>: Dear{" "}
          <span className="text-danger">{"{"}</span>name
          <span className="text-danger">{"}"}</span>, your membership is expired
          soon on <span className="text-danger">{"{"}</span>date
          <span className="text-danger">{"}"}</span>. TQ."
        </p>

        <p className="fs-7 mb-0">
          -{" {"}<span className="text-danger">name</span>{"} "}- indication for your own reference, accept only alphanumeric
        </p>
        <p className="fs-7 mb-0">
          - You may place as many dynamic content as you wish, but to ensure you
          provide the correct info from the excel file
        </p>
        <p className="fs-7 mb-0">
          -{" "}<a
            className="text-decoration-none"
            href={`${process.env.REACT_APP_SERVER_DOMAIN}/meta/message/sampleCustomiseTemplate/xlsx`}
            download={`${process.env.REACT_APP_SERVER_DOMAIN}/meta/message/sampleCustomiseTemplate/xlsx`}
          >
            Sample
          </a>
        </p>
      </Col>
    </div>
  );
}

export default MessageRule;
