import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Create from "../Components/MessageSend/Create";
import Heading from "../Components/Meta/Heading";
import { message } from "../Helpers/useToast";

function MessageSend() {
  const navigate = useNavigate();
  const [isConfirm, setIsConfirm] = useState(false);
  const [submitValue, setSubmitValue] = useState({
    title: "",
    typeLabel: "Normal Message",
    type: 0,
    messageTemplateID: "",
    messageName: "",
    template: "",
    characters: 0,
    credit: 0,
    isUnicode: "",
    sendTo: [],
    isFileUpload: false,
    sendToFile: [],
    sendToFileValid: [],
    sendToFileInValid: [],
    validMessage: [],
    inValidMessage: [],
    isReplaceWord: false,
    isConfirmedFile: false,
    scheduleLabel: "Now",
    scheduleValue: "0",
    broadCastDate: "",
    broadCastTime: "",
  });

  let submitBtn = false;

  if (
    submitValue.title !== "" &&
    submitValue.type !== "" &&
    submitValue.messageTemplateID !== "" &&
    (submitValue.sendTo.length > 0 || submitValue.isFileUpload === true) &&
    submitValue.isConfirmedFile &&
    (submitValue.scheduleValue === "0" ||
      (submitValue.scheduleValue !== "0" &&
        submitValue.broadCastDate !== "" &&
        submitValue.broadCastTime !== ""))
  ) {
    submitBtn = true;
  }

  const submitForm = () => {
    resetForm();
    setIsConfirm(false);
    return [
      message("Message Queue Successfully", "success"),
      navigate("/messageEvent"),
    ];
  };

  const resetForm = () => {
    setSubmitValue({
      title: "",
      typeLabel: "Normal Message",
      type: 0,
      messageTemplateID: "",
      messageName: "",
      template: "",
      characters: 0,
      credit: 0,
      isUnicode: "",
      sendTo: [],
      isFileUpload: false,
      sendToFile: [],
      sendToFileValid: [],
      sendToFileInValid: [],
      validMessage: [],
      inValidMessage: [],
      isReplaceWord: false,
      isConfirmedFile: false,
      scheduleLabel: "Now",
      scheduleValue: "0",
      broadCastDate: "",
      broadCastTime: "",
    });
  };

  return (
    <div>
      <Row>
        <Col sm={10}>
          <Heading design="h4" content={`Send ${submitValue.typeLabel} `} />
        </Col>
      </Row>
      <Row>
        <Create
          submitValue={submitValue}
          setSubmitValue={setSubmitValue}
          submitForm={submitForm}
          submitBtn={submitBtn}
          resetForm={resetForm}
          isConfirm={isConfirm}
          setIsConfirm={setIsConfirm}
        />
      </Row>
    </div>
  );
}

export default MessageSend;
