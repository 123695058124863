import React, { useState } from "react";
import { message } from "../../Helpers/useToast";
import { Accordion, Row, Col, Form, Button } from "react-bootstrap";
import Heading from "../Meta/Heading";
import { BiMessageDetail, BiListUl, BiSend } from "react-icons/bi";
import Multiselect from "multiselect-react-dropdown";
import MessageTemplate from "../Meta/Message/MessageTemplate";
import CustomToggle from "../Meta/CustomToggle";
import moment from "moment";
import readXlsxFile from "read-excel-file";
import Check from "./Check";
import ConfirmToSend from "./ConfirmToSend";

function Create({
  submitValue,
  setSubmitValue,
  submitForm,
  submitBtn,
  resetForm,
  isConfirm,
  setIsConfirm,
}) {
  const [show, setShow] = useState(false);
  const messageTemplateOptions = [
    {
      messageTemplateID: 1,
      type: 1,
      name: "Registration 2022",
      languageID: 1,
      subject: "CompName:",
      message: "Hi, your registration id is {id}",
      characters: 77,
      credit: 1,
      isUnicode: "N",
      status: "N",
      created: "2022-11-25T06:11:19.000Z",
      language: "English",
    },
    {
      messageTemplateID: 2,
      type: 0,
      name: "Food Festival Inviation",
      languageID: 1,
      subject: "CompName:",
      message: "We would like to invite you to our Food Festival",
      characters: 100,
      credit: 1,
      isUnicode: "N",
      status: "N",
      created: "2022-11-25T06:11:19.000Z",
      language: "English",
    },
    {
      messageTemplateID: 3,
      type: 0,
      name: "Garage Gathering",
      languageID: 1,
      subject: "ABC:",
      message: "Let's gather in our garage to have fun",
      characters: 100,
      credit: 1,
      isUnicode: "N",
      status: "N",
      created: "2022-11-25T06:11:19.000Z",
      language: "English",
    },

    {
      messageTemplateID: 4,
      type: 1,
      name: "Offer",
      languageID: 1,
      subject: "ABC:",
      message: "Hi {name}, Grab our donuts to get offers",
      characters: 100,
      credit: 1,
      isUnicode: "N",
      status: "N",
      created: "2022-11-25T06:11:19.000Z",
      language: "English",
    },
  ]
    .map(
      ({
        messageTemplateID,
        name,
        type,
        subject,
        message,
        characters,
        credit,
        isUnicode,
      }) => ({
        label: name,
        value: messageTemplateID,
        type: type,
        template: subject + message,
        characters: characters,
        credit: credit,
        isUnicode: isUnicode,
      })
    )
    .filter((messageTemplateOption) => {
      if (messageTemplateOption.type === submitValue.type) {
        return messageTemplateOption;
      } else {
        return false;
      }
    });
  const sendToOptions = [
    {
      metaID: 14,
      category: "messageSendTo",
      label: "All Active User",
      value: "user",
      sequence: 10,
      status: "N",
    },
    {
      metaID: 11,
      category: "messageSendTo",
      label: "All Active Members",
      value: "activeMember",
      sequence: 30,
      status: "N",
    },
    {
      metaID: 10,
      category: "messageSendTo",
      label: "All Inactive Member",
      value: "inactiveMember",
      sequence: 40,
      status: "N",
    },
  ];
  const scheduleOptions = [
    {
      metaID: 16,
      category: "schedule",
      label: "Now",
      value: "0",
      sequence: 1,
      status: "N",
    },
    {
      metaID: 17,
      category: "schedule",
      label: "Later",
      value: "1",
      sequence: 2,
      status: "N",
    },
  ];
  const messageTypeOptions = [
    {
      metaID: 8,
      category: "messageType",
      label: "Normal Message",
      value: "0",
      sequence: 1,
      status: "N",
    },
    {
      metaID: 9,
      category: "messageType",
      label: "Customise Message",
      value: "1",
      sequence: 2,
      status: "N",
    },
  ];

  const [, setIsExcelFile] = useState({
    file: "",
    fileName: "",
    size: 0,
    fileLength: false,
  });

  const fileUpload = (event) => {
    let excelFile = event.target.files[0];

    //Excel File Validations
    if (event.target.files && event.target.files.length > 0) {
      setIsExcelFile({
        file: excelFile,
        fileName: excelFile.name,
        size: excelFile.size,
        fileLength: true,
      });
      setSubmitValue((submitValue) => ({
        ...submitValue,
        sendToFile: excelFile,
      }));
    } else {
      setSubmitValue((submitValue) => ({
        ...submitValue,
        isFileUpload: false,
        sendToFile: [],
        isConfirmedFile: false,
      }));

      setIsExcelFile({
        file: "",
        fileName: "",
        size: 0,
        fileLength: false,
      });
    }

    if (excelFile === undefined) {
      setSubmitValue((submitValue) => ({
        ...submitValue,
        isFileUpload: false,
        sendToFile: [],
        sendToFileValid: [],
        sendToFileInValid: [],
        isConfirmedFile: false,
      }));

      setIsExcelFile({
        file: "",
        fileName: "",
        size: 0,
        fileLength: false,
      });
      return;
    }

    //Excel File Size Limitation
    if (excelFile.size > 2e6) {
      setSubmitValue((submitValue) => ({
        ...submitValue,
        isFileUpload: false,
        sendToFile: [],
      }));

      setIsExcelFile({
        file: "",
        fileName: "",
        size: 0,
        fileLength: false,
      });

      return message("Please upload a file smaller than 2 MB", "warning");
    }

    if (!excelFile.name.match(/\.(xlsx|csv)$/)) {
      setSubmitValue((submitValue) => ({
        ...submitValue,
        isFileUpload: false,
        sendToFile: [],
      }));
      setIsExcelFile({
        file: "",
        fileName: "",
        size: 0,
        fileLength: false,
      });

      return message(
        "Invalid File Upload. Please Try to Upload file with xlsx",
        "warning"
      );
    }

    readXlsxFile(excelFile).then((rows) => {
      //readinf excel file (rows)
      let validNumber = [];
      let inValidNumber = [];
      let validMessage = [];
      let inValidMessage = [];
      let messageContent;

      rows.forEach(function (eachNumber) {
        const isPhoneRegex = /\d{10,12}$/g;
        let isPhone = eachNumber[0].match(isPhoneRegex);

        if (submitValue.type === 1) {
          // eslint-disable-next-line
          let isReplaceWord = /[^{\}]+(?=})/g;
          let replaceArr = submitValue.template.match(isReplaceWord);
          let replaceWith = eachNumber.slice(1);

          if (replaceArr !== null) {
            var mapping = {};
            replaceArr.forEach((e, i) => (mapping[`{${e}}`] = replaceWith[i]));
            messageContent = submitValue.template.replace(
              /\{\w+\}/gi,
              (n) => mapping[n]
            );
            setSubmitValue((submitValue) => ({
              ...submitValue,
              isReplaceWord: true,
            }));
          } else {
            setSubmitValue((submitValue) => ({
              ...submitValue,
              isReplaceWord: false,
            }));
          }
        }

        if (isPhone === null) {
          inValidNumber.push(eachNumber[0]);
          inValidMessage.push(messageContent);
        } else {
          validNumber.push(eachNumber[0]);
          validMessage.push(messageContent);
        }
        setSubmitValue((submitValue) => ({
          ...submitValue,
          sendToFile: rows,
          isFileUpload: true,
          sendToFileValid: validNumber,
          sendToFileInValid: inValidNumber,
          validMessage: validMessage,
          inValidMessage: inValidMessage,
        }));
      });
    });
  };

  return (
    <Accordion defaultActiveKey="0" flush className="p-4">
      <Accordion.Item className="mb-3 shadow" eventKey="0">
        <Accordion.Header>
          <BiMessageDetail className="text-warning m-2" size={25} />
          <Heading design="h5 fw-bold" content="Message Content" />
        </Accordion.Header>
        <Accordion.Body>
          <Row className="justify-content-md-center">
            <Col sm={8} className="mb-3">
              <Form.Group>
                <Form.Label className="required">Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Eg: Outstanding Payment YYYY-MM-DD Batch X"
                  onChange={(e) =>
                    setSubmitValue((submitValue) => ({
                      ...submitValue,
                      title: e.target.value,
                    }))
                  }
                  value={submitValue.title}
                />
              </Form.Group>
              <p className="text-muted mx-2 mt-1 mb-0 pb-0 fs-7">
                Title is for grouping purpose used in report for easy reference
              </p>
            </Col>
            <Col sm={8} className="mb-3">
              <Form.Group>
                <Form.Label className="required">Message Type</Form.Label>
                <Multiselect
                  displayValue="label"
                  avoidHighlightFirstOption
                  onKeyPressFn={function noRefCheck() {}}
                  onSearch={function noRefCheck() {}}
                  onRemove={(e) =>
                    setSubmitValue((submitValue) => ({
                      ...submitValue,
                      typeLabel: "",
                      type: "",
                    }))
                  }
                  onSelect={(e) =>
                    setSubmitValue((submitValue) => ({
                      ...submitValue,
                      typeLabel: e[0].label,
                      type: parseInt(e[0].value),
                    }))
                  }
                  selectedValues={
                    submitValue.type !== "" && [
                      {
                        label: submitValue.typeLabel,
                        value: submitValue.type,
                      },
                    ]
                  }
                  options={messageTypeOptions}
                  singleSelect
                  placeholder="Select Message Type"
                />
              </Form.Group>
            </Col>

            <Col sm={8} className="mb-3">
              <Form.Group>
                <Form.Label className="required">Message Template</Form.Label>
                <Multiselect
                  className="bg-white"
                  displayValue="label"
                  avoidHighlightFirstOption
                  onKeyPressFn={function noRefCheck() {}}
                  onSearch={function noRefCheck() {}}
                  onRemove={(e) =>
                    setSubmitValue((submitValue) => ({
                      ...submitValue,
                      messageName: "",
                      messageTemplateID: "",
                      template: "",
                      characters: 0,
                      credit: 0,
                      isUnicode: "N",
                    }))
                  }
                  onSelect={(e) =>
                    setSubmitValue((submitValue) => ({
                      ...submitValue,
                      messageName: e[0].label,
                      messageTemplateID: e[0].value,
                      template: e[0].template,
                      characters: e[0].characters,
                      credit: e[0].credit,
                      isUnicode: e[0].isUnicode,
                    }))
                  }
                  selectedValues={
                    submitValue.messageName !== "" && [
                      {
                        label: submitValue.messageName,
                        value: submitValue.messageTemplateID,
                        characters: submitValue.characters,
                        credit: submitValue.credit,
                        isUnicode: submitValue.isUnicode,
                      },
                    ]
                  }
                  options={messageTemplateOptions}
                  selectionLimit={1}
                  placeholder="Select Message Templ.."
                />
              </Form.Group>
            </Col>
            <Col sm={8} className="mb-3">
              <MessageTemplate content={submitValue.template} />
            </Col>
            <Col sm={8} className="mb-3">
              <p className="text-danger fw-bold">
                {submitValue.isUnicode === "Y" && "Unicode Detected"}
              </p>
              <p className="fs-6">
                <input
                  type="num"
                  disabled
                  value={submitValue.characters}
                  style={{ width: "3.5rem" }}
                />{" "}
                characters, this message consuming{" "}
                <input
                  type="num"
                  disabled
                  style={{ width: "3.5rem" }}
                  value={submitValue.credit}
                />{" "}
                credit(s).
              </p>
            </Col>
            <Col sm={8} className="clearfix">
              <CustomToggle
                eventKey="1"
                variant="outline-primary"
                design="float-end"
                disabled={
                  submitValue.title === "" ||
                  submitValue.messageTemplateID === ""
                }
              >
                Next
              </CustomToggle>
            </Col>
          </Row>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item className="mb-3 shadow" eventKey="1">
        <Accordion.Header>
          <BiListUl className="text-warning m-2" size={25} />
          <Heading design="h5 fw-bold" content="Send To" />
        </Accordion.Header>

        <Accordion.Body>
          <Row className="justify-content-md-center">
            <Col sm={8} className="mb-3">
              <Form.Group>
                <Form.Label>Choose Group</Form.Label>
                <Multiselect
                  className="bg-white"
                  displayValue="label"
                  avoidHighlightFirstOption
                  onKeyPressFn={function noRefCheck() {}}
                  onSearch={function noRefCheck() {}}
                  onRemove={(e) =>
                    setSubmitValue((submitValue) => ({
                      ...submitValue,
                      sendTo: e,
                    }))
                  }
                  onSelect={(e) =>
                    setSubmitValue((submitValue) => ({
                      ...submitValue,
                      sendTo: e,
                    }))
                  }
                  selectedValues={submitValue.sendTo}
                  options={sendToOptions}
                  placeholder="Select Message Type"
                  disable={submitValue.isFileUpload}
                />
              </Form.Group>
            </Col>
            <p className="text-center">or</p>
            <Col sm={8} className="mb-3">
              <Form.Group>
                <Form.Label>Import Data</Form.Label>
                <Form.Control
                  type="file"
                  onChange={fileUpload}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  disabled={submitValue.sendTo.length > 0}
                />
              </Form.Group>
              <p className="text-danger mx-2 mt-1 mb-0 pb-0 fs-6">Note</p>
              <p className="text-muted mx-2 mt-1 mb-0 pb-0 fs-7">
                - The file format must be .xlsx
              </p>
              <p className="text-muted mx-2 mt-1 mb-0 pb-0 fs-7">
                - Must have <span className="fw-bold">one column</span> which is{" "}
                <span className="fw-bold"> phone number</span> only because it
                is Normal Message
              </p>
              <p className=" mx-2 mt-1 mb-0 pb-0 fs-7">
                -{" "}
                <a
                  className="text-decoration-none"
                  href={`${process.env.REACT_APP_SERVER_DOMAIN}/meta/message/sampleNormalMessage/xlsx`}
                  download={`${process.env.REACT_APP_SERVER_DOMAIN}/meta/message/sampleNormalMessage/xlsx`}
                >
                  Sample
                </a>
              </p>
              <p className="text-muted mx-2 mt-1 mb-0 pb-0 fs-7">
                - A maximum of <span className="fw-bold">2 MB</span> file size
                can be imported at any one time
              </p>
              <p className="text-danger mx-2 mt-1 mb-0 pb-0 fs-7">
                - Please do format your excel column as Text format. For More
                info to format as text please refer to{" "}
                <a
                  className="text-decoration-none"
                  href={`${process.env.REACT_APP_SERVER_DOMAIN}/meta/message/sampleFormatTemplate/docx`}
                  download={`${process.env.REACT_APP_SERVER_DOMAIN}/meta/message/sampleFormatTemplate/docx`}
                >
                  here
                </a>
              </p>
            </Col>
            <Col sm={8} className="clearfix">
              <CustomToggle
                eventKey="2"
                variant="outline-primary"
                design="float-end"
                disabled={
                  (submitValue.sendTo.length === 0 &&
                    !submitValue.isFileUpload) ||
                  submitValue.template === "" ||
                  !submitValue.isConfirmedFile
                }
              >
                Next
              </CustomToggle>
              {submitValue.sendToFile.length !== 0 &&
                submitValue.template !== "" && (
                  <Button
                    className="float-end mx-1"
                    variant="success"
                    onClick={() => {
                      setShow(true);
                    }}
                  >
                    Check message
                  </Button>
                )}
              <Check
                show={show}
                setShow={setShow}
                submitValue={submitValue}
                setSubmitValue={setSubmitValue}
              />
            </Col>
          </Row>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item className="mb-3 shadow" eventKey="2">
        <Accordion.Header>
          <BiSend className="text-warning m-2" size={25} />
          <Heading design="h5 fw-bold" content="Scheduler" />
        </Accordion.Header>

        <Accordion.Body>
          <Row className="justify-content-md-center">
            <Col sm={8} className="mb-3">
              <Form.Group>
                <Form.Label className="required">Schedule</Form.Label>
                <Multiselect
                  className="bg-white"
                  displayValue="label"
                  avoidHighlightFirstOption
                  onKeyPressFn={function noRefCheck() {}}
                  onSearch={function noRefCheck() {}}
                  onRemove={(e) =>
                    setSubmitValue((submitValue) => ({
                      ...submitValue,
                      scheduleLabel: "",
                      scheduleValue: "",
                      broadCastDate: "",
                      broadCastTime: "",
                    }))
                  }
                  onSelect={(e) =>
                    setSubmitValue((submitValue) => ({
                      ...submitValue,
                      scheduleLabel: e[0].label,
                      scheduleValue: e[0].value,
                      broadCastDate: "",
                      broadCastTime: "",
                    }))
                  }
                  selectedValues={
                    submitValue.scheduleValue !== "" && [
                      {
                        label: submitValue.scheduleLabel,
                        value: submitValue.scheduleValue,
                        broadCastDate: "",
                        broadCastTime: "",
                      },
                    ]
                  }
                  options={scheduleOptions}
                  singleSelect
                  placeholder="Select schedule"
                />
              </Form.Group>
            </Col>
            {submitValue.scheduleValue === "1" && (
              <>
                <Col sm={8} className="mb-3">
                  <Form.Group>
                    <Form.Label className="required">BroadCast Date</Form.Label>
                    <Form.Control
                      type="date"
                      min={moment().format("YYYY-MM-DD")}
                      placeholder="Choose Date"
                      onChange={(e) =>
                        setSubmitValue((submitValue) => ({
                          ...submitValue,
                          broadCastDate: e.target.value,
                        }))
                      }
                      value={submitValue.broadCastDate}
                    />
                  </Form.Group>
                </Col>
                <Col sm={8} className="mb-3">
                  <Form.Group>
                    <Form.Label className="required">BroadCast Time</Form.Label>
                    <Form.Control
                      type="time"
                      min="03:00"
                      placeholder="Choose Time"
                      onChange={(e) =>
                        setSubmitValue((submitValue) => ({
                          ...submitValue,
                          broadCastTime: e.target.value,
                        }))
                      }
                      value={submitValue.broadCastTime}
                    />
                  </Form.Group>
                </Col>
              </>
            )}
            <Col sm={8}>
              <Button
                variant="primary"
                className="mx-1"
                onClick={() => {
                  setIsConfirm(true);
                }}
                disabled={!submitBtn}
              >
                Submit
              </Button>
              <Button
                type="reset"
                variant="outline-secondary"
                className="mx-1"
                onClick={resetForm}
              >
                Clear Form
              </Button>
            </Col>
            <ConfirmToSend
              submitForm={submitForm}
              isConfirm={isConfirm}
              setIsConfirm={setIsConfirm}
              submitValue={submitValue}
              setSubmitValue={setSubmitValue}
            />
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default Create;
