import React from "react";
import { Modal, Button, Row, Col, Form, Table } from "react-bootstrap";
import Heading from "../Meta/Heading";
import moment from "moment";

function Details({ isDetails, setIsDetails, messageList }) {
  const messageLog = [
    {
      "logMessageID": 1,
      "reportMessageID": 1,
      "phoneNumber": 60123456789,
      "template": "CompName: Hi, your registration id is 60123456789",
      "ref": "E2211QbYEnpW9bz",
      "schedule": "2022-11-29T04:30:00.000Z",
      "status": "1",
      "created": "2022-11-29T04:27:13.000Z",
      "tsStatus": "Completed",
      "title": "Registration 2022 Batch 1"
  },
  {
      "logMessageID": 2,
      "reportMessageID": 1,
      "phoneNumber": 60111111111,
      "template": "CompName: Hi, your registration id is 60111111111",
      "ref": "E2211PdyPDlwzbQ",
      "schedule": "2022-11-29T04:30:00.000Z",
      "status": "1",
      "created": "2022-11-29T04:27:13.000Z",
      "tsStatus": "Completed",
      "title": "Registration 2022"
  },
  {
      "logMessageID": 3,
      "reportMessageID": 1,
      "phoneNumber": 60111111112,
      "template": "CompName: Hi, your registration id is 60111111112",
      "ref": "E22119aAD7Bm9ev",
      "schedule": "2022-11-29T03:42:00.000Z",
      "status": "1",
      "created": "2022-11-29T03:31:48.000Z",
      "tsStatus": "Completed",
      "title": "Registration 2022"
  },
  ]

  let eventData = [];
  if (isDetails.id > 0) {
    eventData = messageList.find((eventDataDetail) => {
      if (eventDataDetail.reportMessageID > 0) {
        return eventDataDetail.reportMessageID === isDetails.id;
      } else {
        return false;
      }
    });
  }

  return (
    <Modal
      size="xl"
      show={isDetails.modal}
      onHide={() => setIsDetails({ modal: false, id: 0 })}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <Heading content="View Events" design="h4" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <fieldset disabled>
            <Row className="d-flex align-items-center mb-2">
              <Col sm={2} className="text-end">
                User
              </Col>
              <Col sm={8}>
                <Form.Control placeholder="USER NAME" />
              </Col>
            </Row>
            <Row className="d-flex align-items-center mb-2">
              <Col sm={2} className="text-end">
                Message Type
              </Col>
              <Col sm={8}>
                <Form.Control
                  placeholder={
                    eventData.messageType !== "" &&
                    eventData.messageType !== undefined
                      ? eventData.messageType
                      : ""
                  }
                />
              </Col>
            </Row>
            <Row className="d-flex align-items-center mb-2">
              <Col sm={2} className="text-end">
                Title
              </Col>
              <Col sm={8}>
                <Form.Control
                  placeholder={
                    eventData.title !== "" && eventData.title !== undefined
                      ? eventData.title
                      : ""
                  }
                />
              </Col>
            </Row>
            <Row className="d-flex align-items-center mb-2">
              <Col sm={2} className="text-end">
                Content
              </Col>
              <Col sm={8}>
                <Form.Control
                  as="textarea"
                  placeholder={
                    eventData.subject !== "" &&
                    eventData.message !== "" &&
                    eventData.subject !== undefined &&
                    eventData.message !== undefined
                      ? eventData.subject + eventData.message
                      : ""
                  }
                />
              </Col>
            </Row>
            <Row className="d-flex align-items-center mb-2">
              <Col sm={2} className="text-end">
                No of Contact
              </Col>
              <Col sm={8}>
                <Form.Control
                  placeholder={
                    eventData.totalContact !== "" &&
                    eventData.totalContact !== undefined
                      ? eventData.totalContact
                      : ""
                  }
                />
              </Col>
            </Row>
            <Row className="d-flex align-items-center mb-2">
              <Col sm={2} className="text-end">
                Status
              </Col>
              <Col sm={8}>
                <Form.Control
                  placeholder={
                    eventData.eventStatus !== "" &&
                    eventData.eventStatus !== undefined
                      ? eventData.eventStatus
                      : ""
                  }
                />
              </Col>
            </Row>
            <Row className="d-flex align-items-center mb-2">
              <Col sm={2} className="text-end">
                Broadcast @
              </Col>
              <Col sm={8}>
                <Form.Control
                  placeholder={
                    eventData.broadCast !== "" &&
                    eventData.broadCast !== undefined
                      ? moment(eventData.broadCast).format("YYYY-MM-DD HH:mm")
                      : ""
                  }
                />
              </Col>
            </Row>
          </fieldset>
        </Form>
        <Row className="m-2 p-3">
          <Heading content="Transaction Details" design="h5 mb-2" />
          {messageLog.length > 0 ?
          <Table striped bordered hover responsive className="bg-light">
            <thead>
              <tr>
                <th>Sent @</th>
                <th>Receiver</th>
                <th>Reference ID</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
             
                {messageLog.map((messageLogDetails, index) => {
                  const phoneNumber = messageLogDetails.phoneNumber;
                  const status = messageLogDetails.tsStatus;
                  const schedule = messageLogDetails.schedule;
                  const ref = messageLogDetails.ref;

                  return (
                    <tr key={index}>
                      <td>
                        {schedule !== null
                          ? moment(schedule).format("YYYY-MM-DD HH:mm")
                          : "-"}
                      </td>
                      <td>{phoneNumber}</td>
                      <td>{ref !== null ? ref : "-"}</td>
                      <td>{status}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          :
          <Heading content="No Content yet!" design="h4 text-center text-dark" />
}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => setIsDetails({ modal: false, id: 0 })}
        >
          Back
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Details;
