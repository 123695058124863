import React from 'react'
import Heading from '../Components/Meta/Heading'

function Index() {
  return (
    <div>
      <Heading content="Welcome to One Stop" design="h4"/>
      <Heading content="We are providing multiple services to our client!" design="h6" />
    </div>
  )
}

export default Index
