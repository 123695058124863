import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import { ToastContainer } from "react-toastify";
import { Row } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import Header from "./Components/Meta/Header";
import menu from "./Config/menu";

function App() {
  return (
    <div className="App min-vh-100 p-2 bg-success bg-opacity-10 animation">
      <Row className="mb-5"></Row>
      <Row className="p-3">
        <Header />
        <Router>
          <Routes>
            {menu.map(({ path, name, element }, key) => {
              return (
                <Route
                  key={element}
                  exact
                  path={path}
                  name={name}
                  element={element}
                />
              );
            })}
          </Routes>
        </Router>
      </Row>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
