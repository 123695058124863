import React from "react";
import {
  Modal,
  Button,
  Table,
  Col,
  Row,
  Container,
  Accordion,
  Badge,
} from "react-bootstrap";
import Heading from "../Meta/Heading";
import { BsCoin } from "react-icons/bs";
import { FaSms } from "react-icons/fa";
import Highlighter from "react-highlight-words";
import Content from "../Meta/Message/Content";

function Check({ show, setShow, submitValue, setSubmitValue }) {
  return (
    <Modal
      size="xl"
      backdrop="static"
      show={show}
      onHide={() => setShow(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <Heading content="Uploaded Data" design="h4" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="fs-7 mb-1">
          Please confirm following compiled content before submit
        </p>
        {!submitValue.isReplaceWord && (
          <Container
            fluid
            className="p-3 mb-3 bg-warning bg-opacity-75 bg-gradient border rounded"
          >
            <Row>
              <Col sm={12}>
                Content:{" "}
                <span className="fw-bold">RM0 {submitValue.template}</span>{" "}
              </Col>
              <Col sm={12}>
                Unicode:{" "}
                <span className="fw-bold">
                  {submitValue.isUnicode === "N" ? "No" : "Yes"}
                </span>{" "}
              </Col>
              <Col sm={12}>
                Character:{" "}
                <span className="fw-bold">{submitValue.characters}</span>{" "}
              </Col>
              <Col sm={12}>
                Credit(s) required per message:{" "}
                <span className="fw-bold">{submitValue.credit}</span>{" "}
              </Col>
              <Col sm={12}>
                Total Valid Credit(s):{" "}
                <span className="fw-bold">
                  {submitValue.credit * submitValue.sendToFileValid.length}
                </span>{" "}
              </Col>
            </Row>
          </Container>
        )}
        <Container fluid className="mb-2">
          <Accordion>
            {submitValue.sendToFileValid.length > 0 && (
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  Valid Numbers{" "}
                  <Badge
                    bg="success"
                    className="mx-2 bg-gradient d-flex align-items-center"
                  >
                    <FaSms size={21} className="icon mx-0 me-1 text-warning" />{" "}
                    <span className="text-light">
                      {submitValue.sendToFileValid.length}
                    </span>
                  </Badge>{" "}
                  <Badge
                    bg="success"
                    className="mx-2 bg-gradient d-flex align-items-center"
                  >
                    <BsCoin
                      size={21}
                      className="icon mx-0 me-1 bg-warning rounded-circle text-dark"
                    />{" "}
                    <span className="text-light">
                      {submitValue.credit * submitValue.sendToFileValid.length}
                    </span>
                  </Badge>
                </Accordion.Header>
                <Accordion.Body>
                  <Table striped bordered hover responsive className="fs-7">
                    <thead>
                      <tr>
                        <th>Receiver</th>
                        {submitValue.isReplaceWord && (
                          <>
                            <th>Content</th>
                            <th>Unicode</th>
                            <th>Character</th>
                            <th>Credit(s)</th>
                          </>
                        )}
                      </tr>
                    </thead>
                    {submitValue.isFileUpload &
                      (submitValue.sendToFile.length > 0) && (
                      <tbody>
                        {submitValue.sendToFileValid.map(
                          (phoneNumberDetails, index) => {
                            let phoneNumber = phoneNumberDetails;
                            let messageContent =
                              submitValue.validMessage[index];
                            return (
                              <tr key={index}>
                                <td>{phoneNumber}</td>
                                {submitValue.isReplaceWord && (
                                  <td>
                                    <Highlighter
                                      searchWords={["null"]}
                                      autoEscape={true}
                                      textToHighlight={messageContent}
                                      highlightClassName="text-danger bg-transparent"
                                      unhighlightClassName="text-dark bg-transparent"
                                    />
                                  </td>
                                )}
                                {submitValue.isReplaceWord && (
                                  <>
                                    <td>
                                      <Content
                                        message={messageContent}
                                        getContent="unicodeStatus"
                                      />
                                    </td>
                                    <td>
                                      <Content
                                        message={messageContent}
                                        getContent="totalCharCount"
                                      />
                                    </td>
                                    <td>
                                      <Content
                                        message={messageContent}
                                        getContent="creditcount"
                                      />
                                    </td>
                                  </>
                                )}
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    )}
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            )}
            {submitValue.sendToFileInValid.length > 0 && (
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  Invalid Numbers{" "}
                  <Badge
                    bg="danger"
                    className="mx-2 bg-gradient d-flex align-items-center"
                  >
                    <FaSms size={21} className="icon mx-0 me-1 text-warning" />{" "}
                    <span className="text-light">
                      {submitValue.sendToFileInValid.length}
                    </span>
                  </Badge>{" "}
                  <Badge
                    bg="danger"
                    className="mx-2 bg-gradient d-flex align-items-center"
                  >
                    <BsCoin className="icon mx-0 me-1 bg-warning rounded-circle text-dark" />{" "}
                    <span className="text-light">
                      {submitValue.credit *
                        submitValue.sendToFileInValid.length}
                    </span>
                  </Badge>
                </Accordion.Header>
                <Accordion.Body>
                  <Table striped bordered hover responsive className="fs-7">
                    <thead>
                      <tr>
                        <th>Receiver</th>
                        {submitValue.isReplaceWord && (
                          <>
                            <th>Content</th>
                            <th>Unicode</th>
                            <th>Character</th>
                            <th>Credit(s)</th>
                          </>
                        )}
                      </tr>
                    </thead>
                    {submitValue.isFileUpload &&
                      submitValue.sendToFile.length > 0 && (
                        <tbody>
                          {submitValue.sendToFileInValid.map(
                            (phoneNumberDetails, index) => {
                              let phoneNumber = phoneNumberDetails;
                              let messageContent =
                                submitValue.inValidMessage[index];
                              return (
                                <tr key={index}>
                                  <td>{phoneNumber}</td>
                                  {submitValue.isReplaceWord && (
                                    <td>
                                      <Highlighter
                                        searchWords={["null"]}
                                        autoEscape={true}
                                        textToHighlight={messageContent}
                                        highlightClassName="text-danger bg-transparent"
                                        unhighlightClassName="text-dark bg-transparent"
                                      />
                                    </td>
                                  )}
                                  {submitValue.isReplaceWord && (
                                    <>
                                      <td>
                                        <Content
                                          message={messageContent}
                                          getContent="unicodeStatus"
                                        />
                                      </td>
                                      <td>
                                        <Content
                                          message={messageContent}
                                          getContent="totalCharCount"
                                        />
                                      </td>
                                      <td>
                                        <Content
                                          message={messageContent}
                                          getContent="creditcount"
                                        />
                                      </td>
                                    </>
                                  )}
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      )}
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            )}
          </Accordion>
        </Container>
        <Container fluid>
          <p className="text-danger fs-7 mb-1">
            - Any Date displayed in list are vary based on the Locale Date
            configuration within your Operating System, please test before
            Official Blast.
          </p>
          <p className="text-danger fs-7 mb-3">
            - The rendering mechanism are differ compares from Front-End and
            Back-End, you are strongly adviced to conduct testing before
            Official Blast.
          </p>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          onClick={() => {
            setShow(false);
            setSubmitValue((submitValue) => ({
              ...submitValue,
              isConfirmedFile: false,
            }));
          }}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            setShow(false);
            setSubmitValue((submitValue) => ({
              ...submitValue,
              isConfirmedFile: true,
            }));
          }}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Check;
