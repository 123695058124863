import React from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
import Heading from "../Meta/Heading";
import MessageTemplate from "../Meta/Message/MessageTemplate";
import MessageRule from "../Meta/Message/MessageRule";

function Create({
  show,
  setShow,
  submitValue,
  setSubmitValue,
  submitBtn,
  submitForm,
  resetForm,
  isEdit,
  setIsEdit,
}) {

  const languageOptions = [
    {
        "value": 1,
        "code": "en",
        "label": "English",
        "sequence": 1
    },
    {
        "value": 3,
        "code": "zh",
        "label": "Chinese",
        "sequence": 2
    },
    {
        "value": 5,
        "code": "ms",
        "label": "Malay",
        "sequence": 3
    },
    {
        "value": 2,
        "code": "ta",
        "label": "Tamil",
        "sequence": 4
    },
    {
        "value": 4,
        "code": "th",
        "label": "Thai",
        "sequence": 5
    },
    {
        "value": 6,
        "code": "js",
        "label": "Japanese",
        "sequence": 6
    },
    {
        "value": 8,
        "code": "vs",
        "label": "Vietnamese",
        "sequence": 7
    },
    {
        "value": 7,
        "code": "id",
        "label": "Indonesian",
        "sequence": 8
    }
];

  const messageTypeOptions = [
    {
        "metaID": 8,
        "category": "messageType",
        "label": "Normal Message",
        "value": "0",
        "sequence": 1,
        "status": "N"
    },
    {
        "metaID": 9,
        "category": "messageType",
        "label": "Customise Message",
        "value": "1",
        "sequence": 2,
        "status": "N"
    }
];

  // eslint-disable-next-line
  let checkNonAlphabet = /[^@[\[\]~€{}|^£$¥èéùìòÇ'Øø'ÅåΔ_ΦΓΛΩΠΨΣΘΞ'ÆæßÉ!"#¤%&'()*=,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ'¿abcdefghijklmnopqrstuvwxyzäöñüà\s]/;
  let checkNewLine = /[\n\r]/g;
  // eslint-disable-next-line
  let checkDoubleChar = /[\[\]~€{}|^]/g;
  let checkCountChar = /./g;

  const countChar = (e) => {
    let message = e.target.value;
    let template = submitValue.subject + message;
    //check whether non alphabet
    let checkNonAlphabetStatus = message.search(checkNonAlphabet);

    //char per sms
    let charPerMessageFirst = 161;
    let charPerMessageFollowing = 153;
    let unicodeStatus = false;
    if (checkNonAlphabetStatus >= 0) {
      charPerMessageFirst = 71;
      charPerMessageFollowing = 67;
      unicodeStatus = true;
    }

    //check new line
    let countNewLine = 0;
    let checkNewLineStatus = message.match(checkNewLine);

    if (checkNewLineStatus !== null) {
      countNewLine = checkNewLineStatus.length;
    }

    //check double char Count
    let countDoubleChar = 0;
    let checkDoubleCharStatus = message.match(checkDoubleChar);

    if (checkDoubleCharStatus !== null) {
      countDoubleChar = checkDoubleCharStatus.length;
    }

    // count char
    let subjectcount = submitValue.subject.split(checkCountChar).length - 1;
    let messagecount = message.split(checkCountChar).length - 1;

    //count total char
    let totalCharCount =
      messagecount + subjectcount + countNewLine + countDoubleChar + 4;

    if (checkNewLineStatus !== null) {
      countNewLine = checkNewLineStatus.length;
    }

    //count credit
    let creditcount = 0;
    if (totalCharCount === 0) {
      creditcount = 0;
    } else if (totalCharCount < charPerMessageFirst) {
      creditcount = 1;
    } else {
      creditcount = Math.ceil(totalCharCount / charPerMessageFollowing);
    }

    setSubmitValue((submitValue) => ({
      ...submitValue,
      message: message,
      credit: creditcount,
      subjectcount: subjectcount,
      messagecount: messagecount,
      characters: totalCharCount,
      isUnicode: unicodeStatus,
      template: template,
    }));
  };

  const countSubject = (e) => {
    let subject = e.target.value;
    let template = subject + submitValue.message;

    //check whether non alphabet
    let checkNonAlphabetStatus = subject.search(checkNonAlphabet);

    //char per sms
    let charPerMessageFirst = 161;
    let charPerMessageFollowing = 153;
    let unicodeStatus = false;
    if (checkNonAlphabetStatus >= 0) {
      charPerMessageFirst = 71;
      charPerMessageFollowing = 67;
      unicodeStatus = true;
    }

    //check new line

    let countNewLine = 0;
    let checkNewLineStatus = subject.match(checkNewLine);

    if (checkNewLineStatus !== null) {
      countNewLine = checkNewLineStatus.length;
    }

    //check double char Count
    let countDoubleChar = 0;
    let checkDoubleCharStatus = subject.match(checkDoubleChar);

    if (checkDoubleCharStatus !== null) {
      countDoubleChar = checkDoubleCharStatus.length;
    }

    // count char
    var checkCountChar = /./g;
    let subjectcount = subject.split(checkCountChar).length - 1;
    let messagecount = submitValue.message.split(checkCountChar).length - 1;

    //count total char
    let totalCharCount =
      messagecount + subjectcount + countNewLine + countDoubleChar + 4;

    //count credit
    let creditcount = 0;
    if (totalCharCount === 0) {
      creditcount = 0;
    } else if (totalCharCount < charPerMessageFirst) {
      creditcount = 1;
    } else {
      creditcount = Math.ceil(totalCharCount / charPerMessageFollowing);
    }

    setSubmitValue((submitValue) => ({
      ...submitValue,
      subject: subject,
      credit: creditcount,
      subjectcount: subjectcount,
      messagecount: messagecount,
      characters: totalCharCount,
      isUnicode: unicodeStatus,
      template: template,
    }));
  };

  return (
    <Modal size="xl" show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>
          <Heading content="Create Message Template" design="h4" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Form>
              <Row>
                <Col sm={12} className="mb-3">
                  <Form.Group>
                    <Form.Label className="required">Message Type</Form.Label>
                    <Multiselect
                      displayValue="label"
                      avoidHighlightFirstOption
                      onKeyPressFn={function noRefCheck() {}}
                      onSearch={function noRefCheck() {}}
                      onRemove={(e) =>
                        setSubmitValue((submitValue) => ({
                          ...submitValue,
                          typeLabel: "",
                          type: "",
                        }))
                      }
                      onSelect={(e) =>
                        setSubmitValue((submitValue) => ({
                          ...submitValue,
                          typeLabel: e[0].label,
                          type: e[0].value,
                        }))
                      }
                      selectedValues={
                        submitValue.type !== "" && [
                          {
                            label: submitValue.typeLabel,
                            value: submitValue.type,
                          },
                        ]
                      }
                      options={messageTypeOptions}
                      singleSelect
                      placeholder="Select Message Type"
                    />
                  </Form.Group>
                </Col>
                <Col sm={12} className="mb-3">
                  <Form.Group>
                    <Form.Label className="required">Template Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Template Name"
                      onChange={(e) =>
                        setSubmitValue((submitValue) => ({
                          ...submitValue,
                          name: e.target.value,
                        }))
                      }
                      value={submitValue.name}
                    />
                  </Form.Group>
                </Col>
                <Col sm={12} className="mb-3">
                  <Form.Group>
                    <Form.Label className="required">Language</Form.Label>
                    <Multiselect
                      displayValue="label"
                      avoidHighlightFirstOption
                      onKeyPressFn={function noRefCheck() {}}
                      onSearch={function noRefCheck() {}}
                      onRemove={(e) =>
                        setSubmitValue((submitValue) => ({
                          ...submitValue,
                          language: "",
                          languageID: "",
                        }))
                      }
                      onSelect={(e) =>
                        setSubmitValue((submitValue) => ({
                          ...submitValue,
                          language: e[0].label,
                          languageID: e[0].value,
                        }))
                      }
                      selectedValues={
                        submitValue.language !== "" && [
                          {
                            label: submitValue.language,
                            value: submitValue.languageID,
                          },
                        ]
                      }
                      options={languageOptions}
                      // selectionLimit={1}
                      singleSelect
                      placeholder="Select Language"
                    />
                  </Form.Group>
                </Col>
                <Col sm={12} className="mb-3">
                  <Form.Group>
                    <Form.Label className="required">Subject</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Subject"
                      onChange={countSubject}
                      value={submitValue.subject}
                    />
                  </Form.Group>
                  <p className="text-muted mx-2 mt-1 mb-0 pb-0 fs-7">
                    Suggestion: ABC: <span className="fw-bold">or</span>{" "}
                    XYZ:{" "}
                  </p>
                </Col>
                <Col sm={12} className="mb-3">
                  <Form.Group>
                    <Form.Label className="required">Message</Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="Enter Message"
                      onChange={countChar}
                      value={submitValue.message}
                      style={{ height: "10rem" }}
                    />
                  </Form.Group>
                  {submitValue.type === "2"}
                </Col>
                <Col sm={12} className="mb-3">
                  <p className="text-danger fw-bold">
                    {submitValue.isUnicode && "Unicode Detected"}
                  </p>
                  <p className="fs-6">
                    <input
                      type="num"
                      disabled
                      value={submitValue.characters}
                      style={{ width: "3.5rem" }}
                    />{" "}
                    characters, this message consuming{" "}
                    <input
                      type="num"
                      disabled
                      style={{ width: "3.5rem" }}
                      value={submitValue.credit}
                    />{" "}
                    credit(s).
                  </p>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col>
            <MessageTemplate content={submitValue.template} />
            <MessageRule />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button type="reset" variant="outline-secondary" onClick={resetForm}>
          Reset
        </Button>
        <Button variant="primary" onClick={submitForm} disabled={!submitBtn}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Create;
