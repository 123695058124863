import React from 'react';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { Button } from "react-bootstrap"

function CustomToggle({ children, eventKey, design, variant, disabled }) {
    const decoratedOnClick = useAccordionButton(eventKey);
  return (
  <Button variant={variant} className={design} onClick={decoratedOnClick} disabled={disabled}>
  {children}
</Button>
  )
}

export default CustomToggle