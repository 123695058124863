import React from "react";
import { Col, Placeholder } from "react-bootstrap";
import { BiInfoCircle } from "react-icons/bi";
import ReactTooltip from "react-tooltip";
import Highlighter from "react-highlight-words";

import Heading from "../Heading";

function MessageTemplate(props) {
  const content = props.content;

  let checkNonAlphabet =
    "@[]~€{}|^£$¥èéùìòÇ'Øø'ÅåΔ_ΦΓΛΩΠΨΣΘΞ'ÆæßÉ!\"#¤%&'()*=,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ'¿abcdefghijklmnopqrstuvwxyzäöñüà ";

  let allowedChars = checkNonAlphabet.split("");

  return (
    <div>
      <Col sm={12}>
        <Heading content="Message template" design="h6 text-secondary" />
      </Col>
      {content !== "" ? (
        <>
          <Col
            sm={11}
            className="m-3 p-4 bg-warning bg-opacity-50 border rounded"
          >
            <p>
              RM0{" "}
              <Highlighter
                searchWords={allowedChars}
                autoEscape={true}
                textToHighlight={content}
                highlightClassName="text-dark bg-transparent"
                unhighlightClassName="text-danger bg-transparent"
              />
            </p>
          </Col>
        </>
      ) : (
        <Placeholder as="p" animation="wave">
          <Placeholder
            xs={11}
            bg="warning"
            className="m-3 p-4 border rounded"
          />
        </Placeholder>
      )}
      <Col sm={12} className="mb-3 px-3">
        <p className="fs-7 mb-0">
          For SMS sent to Malaysia, 'RM0 ' will be added, using 4 characters.
          This is to comply with Malaysia's MCMC guidelines.{" "}
          <span className="text-danger">
            Please be aware of your message length.
          </span>
        </p>

        <div className="mb-0 fs-7">
          - 1 credit = 160 characters (English) include <span className="fw-bold">{"RM0<space>"}</span>{" "}
          <BiInfoCircle
            className="text-white bg-primary rounded-circle"
            size={15}
            data-tip
            data-for="alphabetInfo"
          />
          <ReactTooltip
            id="alphabetInfo"
            place="top"
            type="dark"
            effect="float"
          >
            <p className="mb-0">ABCDEFGHIJKLMNOPQRSTUVWXYZ</p>
            <p className="mb-0">abcdefghijklmnopqrstuvwxyz</p>
            <p className="mb-0">0123456789</p>
            <p className="mb-0">@£$¥</p>
            <p className="mb-0">èéùìòÇ'Øø'ÅåΔ_ΦΓΛΩΠΨΣΘΞ'ÆæßÉ</p>
            <p className="mb-0">!"#¤%&'()*=,-./:;?¡</p>
            <p className="mb-0">ÄÖÑÜ'¿äöñüà</p>
          </ReactTooltip>
        </div>

        <div className="mb-0 fs-7">
          - 1 credit = 70 characters (Non-Alphabet)include <span className="fw-bold">{"RM0<space>"}</span>{" "}
          <BiInfoCircle
            className="text-white bg-primary rounded-circle"
            size={15}
            data-tip
            data-for="nonalphabetInfo"
          />
          <ReactTooltip
            id="nonalphabetInfo"
            place="top"
            type="dark"
            effect="float"
          >
            <p className="mb-0">
              Even if you have a single non-alphabet character, the whole
              message gets converted as unicode message(70 characters).
            </p>
          </ReactTooltip>
        </div>

        <div className="mb-0 fs-7">
          - <span className="fw-bold">Emoji</span> is{" "}
          <span className="fw-bold"> prohibited</span>
        </div>

        <div className="mb-0 fs-7">
          - Any of these characters <span className="fw-bold">|^€{}[]~</span>{" "}
          will be considered as{" "}
          <span className="fw-bold">2 regular characters</span>
        </div>

        <div className="mb-0 fs-7">
          - <span className="fw-bold">New Line</span> will be considered as{" "}
          <span className="fw-bold">1</span> character
        </div>
      </Col>
    </div>
  );
}

export default MessageTemplate;
