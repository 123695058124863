import React, { useState } from "react";

import { Col, Row, Button } from "react-bootstrap";
import Create from "../Components/Message/Create";
import Heading from "../Components/Meta/Heading";
import Read from "../Components/Message/Read";

import {message} from "../Helpers/useToast"


function Message() {
  const [show, setShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [submitValue, setSubmitValue] = useState({
    type: 0,
    typeLabel: "Normal Message",
    name: "",
    message: "",
    messagecount: 0,
    language: "",
    languageID: "",
    subject: "",
    subjectcount: 0,
    characters: 4,
    template: "",
    credit: 0,
    isUnicode : false,
  });

  let submitBtn = false;
  if (
    submitValue.name !== "" &&
    submitValue.language !== "" &&
    submitValue.subject !== "" &&
    submitValue.message !== "" &&
    submitValue.characters > 0 &&
    submitValue.credit > 0
  ) {
    submitBtn = true;
  }

  const submitForm = (event) => {
    event.preventDefault();
    message("Successfully Added!", "success")
    resetForm();
    setShow(false);
  };

  const resetForm = () => {
    setSubmitValue({
      type: 0,
      typeLabel: "Normal Message",
      name: "",
      message: "",
      messagecount: 0,
      language: "",
      languageID: "",
      subject: "",
      subjectcount: 0,
      characters: 4,
      template: "",
      credit: 0,
      isUnicode : false,
    });
  };

  return (
    <div>
      <Row>
        <Col sm={10}>
          <Heading design="h4" content="Message Template" />
        </Col>
        <Col sm={2}>
          <Button
            size="sm"
            variant="primary"
            onClick={() => {
              setShow(true);
              setIsEdit(false);
            }}
          >
            Create New
          </Button>
        </Col>
        <Create
          show={show}
          setShow={setShow}
          setSubmitValue={setSubmitValue}
          submitValue={submitValue}
          submitBtn={submitBtn}
          submitForm={submitForm}
          resetForm={resetForm}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
        />
      </Row>
      <Row>
        <Read
          show={show}
          setShow={setShow}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
        />
      </Row>
    </div>
  );
}

export default Message;
