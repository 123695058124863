function Content(props) {
    let message = props.message;
    let getContent = props.getContent;
    // eslint-disable-next-line
    let checkNonAlphabet =/[^@[\[\]~€{}|^£$¥èéùìòÇ'Øø'ÅåΔ_ΦΓΛΩΠΨΣΘΞ'ÆæßÉ!"#¤%&'()*=,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ'¿abcdefghijklmnopqrstuvwxyzäöñüà\s]/;
    let checkNewLine = /[\n\r]/g;
    // eslint-disable-next-line
    let checkDoubleChar = /[\[\]~€{}|^]/g;
    let checkCountChar = /./g;
  
    let checkNonAlphabetStatus = message.search(checkNonAlphabet);
  
    //char per sms
    let charPerMessageFirst = 161;
    let charPerMessageFollowing = 153;
    let unicodeStatus = false;
    if (checkNonAlphabetStatus >= 0) {
      charPerMessageFirst = 71;
      charPerMessageFollowing = 67;
      unicodeStatus = true;
    }
  
    //check new line
    let countNewLine = 0;
    let checkNewLineStatus = message.match(checkNewLine);
  
    if (checkNewLineStatus !== null) {
      countNewLine = checkNewLineStatus.length;
    }
  
    //check double char Count
    let countDoubleChar = 0;
    let checkDoubleCharStatus = message.match(checkDoubleChar);
  
    if (checkDoubleCharStatus !== null) {
      countDoubleChar = checkDoubleCharStatus.length;
    }
  
    // count char
    let messagecount = message.split(checkCountChar).length - 1;
  
    //count total char
    let totalCharCount = messagecount + countNewLine + countDoubleChar + 4;
  
    if (checkNewLineStatus !== null) {
      countNewLine = checkNewLineStatus.length;
    }
  
    //count credit
    let creditcount = 0;
    if (totalCharCount === 0) {
      creditcount = 0;
    } else if (totalCharCount < charPerMessageFirst) {
      creditcount = 1;
    } else {
      creditcount = Math.ceil(totalCharCount / charPerMessageFollowing);
    }
  
    let content;
    switch (getContent) {
      case "creditcount":
        content = creditcount;
        break;
      case "totalCharCount":
        content = totalCharCount;
        break;
      case "unicodeStatus":
        if (unicodeStatus) {
          content = "Yes";
        } else {
          content = "No";
        }
  
        break;
      default:
        content = message;
    }
  
    return content;
  }
  
  export default Content;  